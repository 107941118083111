<template>
  <div class="wrapper">
    <Crumb :title.sync="title" :menu.sync="menu" />
    <Banner :img.sync="img" />
    <div class="container">
      <div class="content">
        <p>社会各界朋友们：</p>
        <p>
          衷心感谢长期以来对长春凯达发展有限公司的关心与支持！胸怀千秋伟业，恰是百年风华。中国共产党即将迎来百年华诞，我们一同向伟大的中国共产党致以最美好的祝福！
        </p>
        <p>
          凯达公司与吉林全面振兴、全方位振兴发展同进步，在落实“支持一汽建设世界一流企业、建设世界一流汽车城”战略，推动长春国际汽车城稳步向万亿级产值迈进的过程中，凯达公司作为重要载体，肩负着推进长春国际汽车城建设的重要历史使命，承载着引领区域振兴发展的殷切期望。通过近十六年的发展历程，特别是党的十九届五中全会以来，企业上下深刻认识全面深化改革新任务、新要求，在做大做强国有企业进程中取得阶段性成果。紧紧围绕“融、投、建、管、退”五大职能，承载重大功能性项目和产业投资项目，持续推动地方经济建设及城市基础设施建设，建立具备持续投融资发展能力的现代化、市场化企业集团，资产总额已突破200亿元，为国有资产保值、增值和长春国际汽车城建设，做出了应有的贡献。
        </p>
        <p>
          最让我们自豪的是习近平总书记视察吉林、亲临一汽，做出了“风景这边独好”的高度赞誉，给我们巨大的鼓舞。建设好所承接的长春国际汽车城项目，打造精品、样板工程，是凯达人向政府和人民做出的庄严承诺。对标长春国际汽车城“双一流”、“万亿级”战略目标，凯达公司将以“一流的理念、一流的人才、一流的团队、一流的管理、一流的平台、一流的品质”六个一流建设，实现企业“双千亿”为发展战略，我们干在实处永无止境，在重点领域谋划一批具有全局性、引领性、标志性意义的重大项目。
        </p>
        <p>
          春来潮涌东风劲，扬帆奋进正当时。展望“十四五”，凯达公司将以“建设世界一流汽车城先行者”为使命，以“融、投、建、管、退”为主线，以改革创新为根本动力，牢牢抓住“一主、六双”产业空间布局、长春“四大板块”建设和“长春国际化都市圈”建设的战略机遇期，全面、精准承接一汽“六个回归”，形成具有竞争力的增长极。全体凯达人将奉行“为使命而战、为荣誉而战、为梦想而战”的新要求，奋力顽强拼搏，为实现长春国际汽车城“双一流”、“万亿级”的战略目标而不懈奋斗，以优异成绩向中国共产党建党100周年献礼。
        </p>
        <p class="align-right">董事长：孙守辉</p>
        <p class="align-right">二〇二一年六月</p>
      </div>
    </div>
  </div>
</template>

<script>
import Crumb from "@/components/Crumb.vue";
import Banner from "@/components/Banner.vue";
export default {
  data() {
    return {
      title: "",
      menu: [],
      img: require("@/assets/images/banner1.jpg"),
    };
  },
  components: { Crumb, Banner },
};
</script>

<style scoped lang="less">
.wrapper {
  .container {
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    padding: 30px 0;
    .title {
      text-align: center;
      margin-bottom: 30px;
      border-bottom: 1px solid #ddd;
      h1 {
        font-size: 28px;
        line-height: 60px;
        font-weight: normal;
        text-align: center;
      }
      p {
        font-size: 14px;
        color: #999;
        margin-bottom: 20px;
        span {
          margin: 0 20px;
        }
      }
    }
    .content {
      p {
        margin-bottom: 1em;
      }
      .align-right {
        text-align: right;
      }
    }
  }
}
</style>
